import React, { useContext } from 'react';
import { Location } from '@reach/router';
import { useAuthState } from 'react-firebase-hooks/auth';

let firebaseApp;
let firebase;

if (typeof window !== 'undefined') {
  firebase = require('firebase');
  ({ firebaseApp } = require('../../firebase/config'));
}

const MembersContext = React.createContext({});

export const useFirebaseAuth = () => useContext(MembersContext);

const FirebaseAuthProvider = props => {
  /* user state */
  const [user, loading, error] = useAuthState(
    firebaseApp?.auth() || { currentUser: undefined },
  );

  /* logout method */
  const logout = () => firebase?.auth().signOut();

  /* context values */
  const values = {
    isLoggedIn: !loading && !!user,
    // setLogin,
    user,
    loading,
    isLoading: loading,
    error,
    logout,
  };
  return (
    <MembersContext.Provider value={values}>
      {props.children}
    </MembersContext.Provider>
  );
};

const WithLocation = props => (
  <Location>
    {location => <FirebaseAuthProvider {...location} {...props} />}
  </Location>
);

WithLocation.displayName = 'FirebaseAuthProvider';

export default WithLocation;
