import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { fontSize, space } from 'styled-system';
import { Link as GatsbyLink } from 'gatsby';

const Wrapper = styled.div`
  ${fontSize}
  ${space}
  ${({ theme }) => css`
    font-weight: 500;
    color: ${theme.colors.chestnutRose};
    text-decoration: none;
  `}
`;

const Link = ({ to, target, disabled, ...props }) => {
  const isLocal = to?.includes('http://localhost');
  const isExternal = !isLocal && to?.includes('http');
  const link = isLocal ? to.replace('http://localhost', '') : to;
  if (disabled) {
    return props.children;
  }
  return (
    <Wrapper {...props}>
      {isExternal ? (
        <a href={link} target={target} rel="noopener noreferrer" {...props} />
      ) : (
        <GatsbyLink to={link} target={target} {...props} />
      )}
    </Wrapper>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
};

export default Link;
