import React, { useState } from 'react';

export const PreviewContext = React.createContext();

const PreviewProvider = ({ children }) => {
  const [values, setValues] = useState();
  const context = {
    ...values,
    setValues,
  };
  return (
    <PreviewContext.Provider value={context}>
      {children}
    </PreviewContext.Provider>
  );
};

export default PreviewProvider;
