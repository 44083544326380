let firebase;

if (typeof window !== 'undefined') {
  firebase = require('firebase');
}

const lazyFirebase = firebase;

export const firebaseConfig = {
  apiKey: 'AIzaSyDdkdScrtHL3XdHEh-vCD_MWMxLvogG_ck',
  authDomain: 'bbsh-member-portal.firebaseApp?.com',
  databaseURL: 'https://bbsh-member-portal.firebaseio.com',
  projectId: 'bbsh-member-portal',
  storageBucket: 'bbsh-member-portal.appspot.com',
  messagingSenderId: '1008029050740',
  appId: '1:1008029050740:web:02bae0526364a12553ae21',
};

export const firebaseApp =
  typeof window !== undefined
    ? firebase?.initializeApp(firebaseConfig)
    : undefined;

export default lazyFirebase;
