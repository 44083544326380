import theme from 'styled-theming';

export const grays = {
  100: '#F2F2F9',
  200: '#E2E2E2',
  300: '#CCCCCC',
  400: '#818181',
  800: '#333333',
};

export const colors = {
  valhalla: '#311E5E',
  tulipTree: '#E8B42E',
  chestnutRose: '#D46C6D',
  trendyPink: '#947CA4',
  mountainMeadow: '#00BB7C',
  coralRed: '#F5464F',
  grays,
  primary: grays['800'],
  secondary: grays['400'],
  tertiary: grays['300'],
  negative: grays['200'],
};

const borderRadius = '12px';

const borderColor = grays['200'];

const borderWidth = '1px';

const border = `${borderWidth} solid ${borderColor}`;

const background = theme('variant', {
  primary: grays['100'],
});

const color = {
  primary: grays['800'],
  secondary: grays['400'],
  tertiary: grays['300'],
  negative: grays['200'],
};

const space = [0, 4, 8, 16, 24, 32, 48, 64, 128, 256];

export const defaultTheme = {
  variant: 'primary',
  space,
  color,
  borderRadius,
  borderColor,
  borderWidth,
  border,
  grays,
  background,
  colors,
};
