import React, { useContext } from 'react';
import { background, overflow, height } from 'styled-system';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'rebass';
import CoreText from '../Text';
import Container from '../Container';
import HeaderHeightContext from '../Layout/context';
import { FiChevronDown } from 'react-icons/fi';
import Img from 'gatsby-image';
import Link from '../Link';
import Button from '../Button';

const Wrapper = styled(Flex)`
  ${background}
  ${overflow}
  ${({ offset }) => css`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: calc(90vh - ${offset}px);
    /* margin-bottom: ${offset}px; */
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0%;
      width: 100%;
      height: 100vh;
      opacity: 0.78;
      z-index: 1;
      background-color: #462655;
    }
    & > :not(img) {
      z-index: 2;
    }
  `}
`;
Wrapper.defaultProps = {
  py: [7, 8],
};

const Image = styled(Img)`
  ${height}
  top: 0;
  left: 0;
  width: 100%;
  position: absolute !important;
  object-fit: cover;
  z-index: 0 !important;
`;
Image.defaultProps = {
  height: '100vh',
};

const Text = styled(CoreText)`
  color: white;
  text-align: center;
`;

const TextPosition = styled(Flex)`
  flex-direction: column;
  align-items: center;
  /* transform: translateY(-50%); */
`;

const ArrowDown = styled(FiChevronDown).attrs(() => ({
  size: 24,
  color: 'white',
}))`
  transform: translateX(-50%) scale(6.6, 1.8);
  position: absolute;
  left: 50%;
  bottom: 8px;
  stroke-width: 1;
  opacity: 0.25;
`;

const BottomChildren = styled(Box)`
  z-index: 2;
  position: relative;
`;

const HeroLayout = ({
  title,
  description,
  imageFluid,
  children,
  overflow,
  imageHeight,
  showArrow,
  button,
}) => {
  const headerHeight = useContext(HeaderHeightContext);
  return (
    <>
      <Wrapper offset={headerHeight} overflow={overflow}>
        <Image height={imageHeight} objectFit="cover" fluid={imageFluid} />
        <Container fluid>
          <TextPosition>
            <Text mb={3} as="h1" fontSize={[5, 6]}>
              {title}
            </Text>
            <Text fontSize={2}>{description}</Text>
            {button && (
              <Link to={button.to}>
                <Button {...button}>{button.text}</Button>
              </Link>
            )}
          </TextPosition>
        </Container>
        {showArrow && <ArrowDown />}
      </Wrapper>
      {children && (
        <BottomChildren mt={3}>
          <Container maxWidth={1140}>{children}</Container>
        </BottomChildren>
      )}
    </>
  );
};

HeroLayout.defaultProps = {
  showArrow: true,
  title: 'Become a Professional Healer',
  description: 'Make a difference in your life and in lives of others',
  image:
    'https://images.unsplash.com/photo-1505816014357-96b5ff457e9a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=60',
};

export default HeroLayout;
