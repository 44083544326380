/* eslint-disable react/prop-types */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider as AlertProvider } from 'react-alert';

import GlobalStyle from './Layout/GlobalStyle';
import { defaultTheme } from '../theme';
import ErrorBoundary from './ErrorBoundary';
import FirebaseAuthProvider from './FirebaseAuth';
import PreviewProvider from './Preview';
import { ReactAlertTemplate } from './Alert';

const WrapRoot = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorBoundary>
        <FirebaseAuthProvider>
          <PreviewProvider>
            <AlertProvider
              template={ReactAlertTemplate}
              position="bottom right"
            >
              <GlobalStyle />
              {children}
            </AlertProvider>
          </PreviewProvider>
        </FirebaseAuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default WrapRoot;
