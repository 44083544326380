import { Button as Base } from 'rebass';
import styled, { css } from 'styled-components';

const variants = theme => ({
  primary: css`
    background: ${theme.colors.valhalla};
    &:hover:not(:disabled):not(:active) {
      background: rgb(82, 58, 144);
      box-shadow: 0 4px 14px 0 rgba(103, 50, 230, 0.3),
        0 0 54px 0 rgba(103, 50, 230, 0.3);
    }
    &:active:not(:disabled) {
      background: rgb(44, 32, 76);
    }
  `,
  secondary: css`
    background: linear-gradient(144.22deg, #c54371 0%, #dfa579 100%);
    &:hover:not(:disabled):not(:active) {
      background: linear-gradient(144.22deg, #de6484 0%, #e89e8a 100%);
      box-shadow: 0 4px 14px 0 rgba(212, 108, 109, 0.3),
        0 0 54px 0 rgba(212, 108, 109, 0.3);
    }
    &:active:not(:disabled) {
      background: linear-gradient(144.22deg, #b03a5b 0%, #ba7360 100%);
    }
  `,
  white: css`
    background: white;
    &:not(:disabled) {
      color: ${theme.colors.chestnutRose};
    }
    &:hover:not(:active):not(:disabled) {
      color: rgb(240, 138, 143);
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1), 0 0 54px 0 rgba(0, 0, 0, 0.1);
    }
    &:active:not(:disabled) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  `,
  minimal: css`
    background: transparent;
    padding: 8px;
    &:not(:disabled) {
      color: ${theme.colors.chestnutRose};
    }
    &:hover:not(:active):not(:disabled) {
      color: rgb(240, 138, 143);
      box-shadow: none;
    }
    &:active:not(:disabled) {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &:disabled {
      color: ${theme.colors.grays['100']};
      background: transparent;
    }
  `,
  outline: css`
    background: transparent;
    box-shadow: inset 0 0 0 2px ${theme.colors.valhalla};
    color: ${theme.colors.valhalla};
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  `,
  /* TODO: colored outline */
});

const Button = styled(Base)`
  ${({ variant, theme }) => css`
    border-radius: 100px;
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.1s ease-out;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      background: rgba(129, 129, 129, 0.4);
    }
    ${variants(theme)[variant || theme.variant]}
  `}
`;

Button.defaultProps = {
  py: 3,
  px: 4,
};

Button.propTypes = {};

export default Button;
