import styled, { css } from 'styled-components';
import { maxWidth } from 'styled-system';
import { Box } from 'rebass';

const Container = styled(Box)`
  ${maxWidth}
  width: 100%;
  ${({ fluid }) => {
    if (fluid)
      css`
        max-width: none;
      `;
  }}
`;

Container.defaultProps = {
  maxWidth: [720, 960, 1140, 1280],
  px: [3, 4],
  mx: 'auto',
};

export default Container;
