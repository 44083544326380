import styled, { css } from 'styled-components';
import { Card as CoreCard } from 'rebass';
import { display, borderRadius } from 'styled-system';

const Card = styled(CoreCard)`
  ${display}
  ${({ theme }) => css`
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.04);
    border-radius: ${theme.borderRadius};
  `}
  ${borderRadius}
`;

Card.defaultProps = {
  backgroundColor: 'white',
};

export default Card;
