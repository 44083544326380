import { Text as Base } from 'rebass';
import styled from 'styled-components';
import { display } from 'styled-system';

const Text = styled(Base)`
  ${display}
  color: ${({ color, variant, theme }) => color || theme.color[variant]};
  line-height: 1.5;
`;

Text.defaultProps = {
  variant: 'primary',
};

export default Text;
