import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import Card from '../Card';
import { FiCheck, FiX, FiAlertCircle } from 'react-icons/fi';
import styled, { keyframes } from 'styled-components';
import Text from '../Text';

const getBgByType = type => {
  switch (type) {
    case 'success':
      return 'mountainMeadow';
    case 'warning':
      return 'tulipTree';
    case 'danger':
      return 'coralRed';
    default:
      return 'white';
  }
};

const getIconByType = type => {
  switch (type) {
    case 'success':
      return <FiCheck size={24} />;
    case 'danger':
      return <FiX size={24} />;
    case 'warning':
      return <FiAlertCircle size={24} />;
    default:
      return null;
  }
};

const timeoutAnimation = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

const TimeoutBar = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.75);
  animation: ${timeoutAnimation};
  animation-duration: ${({ timeout }) => timeout}ms;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-play-state: running;
  position: absolute;
  transform-origin: left;
  bottom: 0;
  left: 0;
`;

const Alert = ({ type, children, icon, iconColor, color, ...props }) => {
  return (
    <Card
      borderRadius="8px"
      p={3}
      color={color}
      backgroundColor={getBgByType(type)}
      {...props}
    >
      <Flex alignItems="center">
        <Text color={iconColor || color} css={{ display: 'inline-flex' }}>
          {icon || getIconByType(type)}
        </Text>
        <Box ml={2}>{children}</Box>
      </Flex>
    </Card>
  );
};

Alert.defaultProps = {
  color: 'white',
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'warning', 'danger']),
};

export const ReactAlertTemplate = ({ options, ...props }) => (
  <Alert css={{ position: 'relative' }} {...options} {...props}>
    <TimeoutBar timeout={options.timeout} />
    {options.children}
  </Alert>
);

export default Alert;
